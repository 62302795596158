import React from "react"
import PageHeading from "../../utils/page-heading"
import { StaticImage } from "gatsby-plugin-image"

const ContactIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/ContactPage.png"
        placeholder="blurred"
        className="w-full"
        alt='Contact Us'
      />

      <PageHeading text="Contact us" />
      <p>
        To keep growing Design talents in Nigeria through valuable processes is
        our dream. Help us keep it alive, we make every penny count.
      </p>
    </>
  )
}

export default ContactIntro
