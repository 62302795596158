import React from "react"
import PagesLayout from "../components/layout/pages"
import ContactUsForm from "../components/pages/contact/form"
import ContactIntro from "../components/pages/contact/intro"

const Contact = () => {
  return (
    <PagesLayout title="Contact Us">
      <ContactIntro />
      <ContactUsForm />
    </PagesLayout>
  )
}

Contact.propTypes = {}

export default Contact
