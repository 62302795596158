import React from "react"
import SubmitButton from "../../../utils/button/submit"
import FormSuccess from "../../../utils/form/success"
import FormError from "../../../utils/form/error"
import useForm from "../../../../hooks/useForm"

const ContactUsForm = () => {
  const { onSubmit, isLoading, isError, isSuccess, errorMessage } = useForm();

  return (
    <>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-wrapper">
          {/* Full Name */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="fullname"
              placeholder="Full Name"
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <input
              type="email"
              required="required"
              name="emailAddress"
              placeholder="Email Address"
            />
          </div>

          {/* Message/Reason for Contacting */}
          <div className="form-group lg:col-span-full">
            <input
              type="text"
              required="required"
              name="message"
              placeholder="Message/Reason for contacting"
            />
          </div>

          <div className="form-group md-max:mt-5">
            <SubmitButton disabled={isLoading}/>
          </div>
        </div>
      </form>
      <div>
        {isSuccess && <FormSuccess message="Message has been delivered succcesfully"/>}
        {isError && <FormError message={errorMessage}/>}
      </div>
    </>
  )
}

ContactUsForm.propTypes = {}

export default ContactUsForm
